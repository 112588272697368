import React from "react"

const Share = () => (
    <nav className="social-nav">
        <h4>Share</h4>
        <ul>
            <li className="linkedin">
                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/solutions/im-start-business/&amp;title=Companies&amp;summary=Companies&amp;source=https%3A//salestribe.com/solutions/im-start-business/" rel="noopener noreferrer" target="_blank">
                    <span className="sr-only">LinkedIn</span>
                    <span className="icon icon-linkedin"></span>
                </a>
            </li>
            <li className="twitter">
                <a href="https://twitter.com/intent/tweet/?text=Companies&amp;url=https%3A//salestribe.com/solutions/im-start-business/" rel="noopener noreferrer" target="_blank">
                    <span className="sr-only">Twitter</span>
                    <span className="icon icon-twitter"></span>
                </a>
            </li>
            <li className="googleplus">
                <a href="https://plus.google.com/share?url=https%3A//salestribe.com/solutions/im-start-business/" rel="noopener noreferrer" target="_blank">
                    <span className="sr-only">Google+</span>
                    <span className="icon icon-google-plus"></span>
                </a>
            </li>
            <li className="facebook">
                <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/solutions/im-start-business/" rel="noopener noreferrer" target="_blank">
                    <span className="sr-only">Facebook</span>
                    <span className="icon icon-facebook-f"></span>
                </a>
            </li>
            <li className="email">
                <a href="mailto:?subject=Companies&amp;body=https%3A//salestribe.com/solutions/im-start-business/" rel="noopener noreferrer" target="_blank">
                    <span className="sr-only">Email</span>
                    <span className="icon icon-envelope"></span>
                </a>
            </li>
        </ul>
    </nav>
)

export default Share