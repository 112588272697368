import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FooterAlternate1, FooterAlternate3 } from "../../components/footer"
import Share from "../../components/share"
import SEO from "../../components/seo"

const IndexPage = ({ location }) => (
    <Layout location={location}>
            <SEO title="Sales Professionals" />

            <nav className="share-bar">
            <ul>
                <li>
                    <Link to="/enquiries/" className="btn btn-default">Book an assessment</Link>
                </li>
            </ul>
            </nav>
            <article className="article">
                <header className="header header--primary article__header">
                    <div className="header__image">
                        <div className="header__bgimage" style={{backgroundImage:"url(/media/svg/solution-header_sales-professionals.svg)"}}></div>
                    </div>
                    <div className="header__content">
                        <div className="container">
                            <div className="row">
                                <div className="header__inner col-sm-9 col-md-7 col-lg-6 col-lg-offset-1">
                                    <Link to="/solutions/" className="header__back back-link">
                                        <span className="sr-only">Back to all articles</span>
                                        <i className="icon"></i>
                                    </Link>
                                    <div className="header__title">
                                        <h3>Solutions</h3>
                                        <h1>Sales People</h1>
                                    </div>
                                    <h3 className="header__caption">.....but have you ever thought of yourself as a brand?</h3>
                                    <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container" id="article-content">
                    <div className="row">
                        <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7 large">
                            <h3>Are you equipped to face the today&#39;s challenges in B2B sales?</h3>
                            <div className="rich-text">
                                <p></p>
                                <p>The future is for those sales professionals who can differentiate themselves with a personal brand that sets them apart from all of the other sales people out there, and who are able to convey a unique promise of value.</p>
                                <p>

                                    The future is for digitally savvy sales people who use all the channels they can to connect with their target customers, share unique insights and establish their thought leadership.
                                    <br/>
                                </p>
                                <p>You must become digitally driven, socially connected, mobile and highly specialised. Let SalesTribe show you how. Join today - it's free!</p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                            </div>
                        </div>
                        <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
                            <div className="article__sidebar-wrap" id="article-sidebar">
                                <h4>Assessments from:</h4>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <h2 className="list-group-item-heading">$600 AUD</h2>
                                        <p className="list-group-item-text">Online package</p>

                                    </li>
                                </ul>
                                <div className="article__sidebar-btns">
                                    <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                                    <Link to="/enquiries/" className="btn btn-link">Contact an advisor</Link>
                                </div>
                                <Share />
                            </div>
                        </aside>
                    </div>
                </div>
            </article>
            <div className="block block--base benefits--small benefits">
                <div className="container">
                    <div className="block__header row">
                        <div className="col-sm-12 col-md-offset-1 col-md-10">
                            <h2>Solution to get you business ready</h2>
                            <div className="block__caption">
                                <h3>We tailor solutions to you, understanding what your career needs to get you ready to work with a new business.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Idea-Man@2x.png" height="131" src="/media/images/Idea-Man2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Personal Branding Package<br/>From AUD $1,500</strong></h3>
                                <div className="benefits__caption">
                                    <p>Cultivating a strong personal brand is now critical to your career in sales because it will provide you with a massive edge over the vast majority of &#39;generalist&#39; sales people. This package will provide the foundations upon which you can build your career as a key person of influence in your industry.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="User@2x.png" height="131" src="/media/images/User2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Resume Review<br/>From AUD $470</strong></h3>
                                <div className="benefits__caption">
                                    <p>Get yourself a keyword optimized resume that will elevate you above all the filters. To set yourself apart from all the other applicants you need to be optimized to pass through Applicant Tracking Systems. This package will ensure that your CV is aligned with your personal brand.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Speech-Bubbles@2x.png" height="131" src="/media/images/Speech-Bubbles2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Career mentoring<br/>From $750 AUD (for 3 sessions)</strong></h3>
                                <div className="benefits__caption">
                                    <p>Successful sales people, athletes and sporting teams rely on coaching to get them where they want to be. SalesTribe offers professional mentoring, guidance and career transition advisory to help ensure that you remain relevant in these changing times.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Maze@2x.png" height="131" src="/media/images/Maze2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Career Planning<br/>From $600</strong></h3>
                                <div className="benefits__caption">
                                    <p>If you are a &#39;generalist&#39; sales person, your career is now under threat. With our career planning package you will receive a step by step guide on how to move from generalist to &#39;specialist&#39; sales person. This is your first step to getting re-purposed and then re-connected into an exciting business.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Mega-Phone@2x.png" height="131" src="/media/images/Mega-Phone2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Social Voice Strategy<br/>From AUD $200</strong></h3>
                                <div className="benefits__caption">
                                    <p>Social selling is probably the single hottest topic today in the world of B2B sales. Work with a SalesTribe mentor to ensure that you are getting cut-through on social channels. If you&#39;re not being heard, then you are not being considered.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Video@2x.png" height="131" src="/media/images/Video2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Video Profiles<br/>From AUD $2,300</strong></h3>
                                <div className="benefits__caption">
                                    <p>Video profiles are fast becoming one of the most important tools for business professionals. Employers want to see a video profile before they read your CV, and this is a great way to set yourself apart from all of the other sales professionals that are now looking for new employment opportunities.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Star@2x.png" height="131" src="/media/images/Star2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Linkedin Profile Optimisation<br/>From $200 AUD</strong></h3>
                                <div className="benefits__caption">
                                    <p>Your LinkedIn profile is your 24x7 online advertisement. Create a professional profile that is optimized to ensure that your &#39;personal brand&#39;, and your &#39;unique promise of value&#39; is being clearly articulated to your target audience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="benefits__item col-sm-4">
                            <div className="benefits__image">
                                <img alt="Male@2x.png" height="131" src="/media/images/Male2x.2e16d0ba.fill-131x131.png" width="131" />
                            </div>
                            <div className="benefits__copy">
                                <h3 className="benefits__title"><strong>Job Interview Training<br/>From AUD $250</strong></h3>
                                <div className="benefits__caption">
                                    <p>For some reason, sales people are notoriously bad when it comes to selling themselves. Whilst the interview is increasingly seen as ineffective by many, even the best sales people still need to prepare for job interviews. This package will ensure that you have all of the tools required to make a great first impression.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="block__footer row">
                        <div className="col-sm-12 col-md-offset-1 col-md-10">
                            <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block block--base block--ruledtop how-it-works">
                <div className="container">
                    <div className="block__header row">
                        <div className="col-sm-offset-1 col-sm-10">
                            <h2>How it works</h2>
                        </div>
                    </div>
                    <div className="how-it-works__wrap row">
                        <div className="how-it-works__svg container">
                            <div className="how-it-works__svgwrap">
                                <div className="hidden-xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 270" width="1133" height="270" preserveAspectRatio="xMinYMin">
                                        <defs>
                                            <clipPath id="landingMaskA">
                                                <path d="M0 0h500v270H0zM500 0h133v30H500zM633 0h500v270H633zM500 240h133v30H500z" />
                                            </clipPath>
                                        </defs>
                                        <g clipPath="url(#landingMaskA)">
                                            <path fill="none" stroke="#e1e1e1" strokeWidth="10" strokeMiterlimit="10" d="M155 135h160a50 50 0 0 0 50-50V55a50 50 0 0 1 50-50h110a40 30 0 0 1 40 30v200a40 30 0 0 0 40 30h110a50 50 0 0 0 50-50v-40a50 50 0 0 1 50-50h160" />
                                            <path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M155 135h160a50 50 0 0 0 50-50V55a50 50 0 0 1 50-50h110a40 30 0 0 1 40 30v200a40 30 0 0 0 40 30h110a50 50 0 0 0 50-50v-40a50 50 0 0 1 50-50h160" />
                                        </g>
                                    </svg>
                                </div>
                                <div className="visible-xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 800" width="768" height="800" preserveAspectRatio="xMinYMin">
                                        <path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M500 100h300m0 225H409a25 25 0 0 0-25 25m0 220v40" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row">
                                <Link to="/how-it-works/sales-professionals/" className="how-it-works__item col-xs-12 col-sm-4">
                                    <img src="/media/svg/landing-256x183_sales-professionals.svg" alt="" />
                                    <h3 className="how-it-works__title">In between roles? Looking for a new sales role?</h3>
                                    <p className="how-it-works__caption">SalesTribe works with sales people whom are now ready for change......</p>
                                    <button className="btn btn-link">Solutions for sales people</button>
                                </Link>
                                <div className="how-it-works__item middle col-xs-12 col-sm-4">
                                    <img src="/static/images/svg/landing-256x183_brand.svg" alt="" />
                                    <h3 className="how-it-works__title">Connect through SalesTribe</h3>
                                    <p className="how-it-works__caption">Providing sales people job readiness and connections to suitable businesses</p>
                                </div>
                                <Link to="/how-it-works/start-businesses/" className="how-it-works__item col-xs-12 col-sm-4">
                                    <img src="/media/svg/landing-256x183_startups.svg" alt="" />
                                    <h3 className="how-it-works__title">Does your business need to change how you sell?</h3>
                                    <p className="how-it-works__caption">SalesTribe specialises in digital sales transformation</p>
                                    <button className="btn btn-link">Solutions for companies</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="block__footer row">
                        <div className="col-sm-12 col-md-offset-1 col-md-10">
                            <Link to="/how-it-works/" className="btn btn-link">Learn more</Link>
                        </div>
                    </div>
                </div>
            </div>

            <FooterAlternate3 />
            <FooterAlternate1 />

        </Layout>
)

export default IndexPage
